// A function named sendMail() is defined here.
// This function is called when the user clicks on the submit button.
// The function simulate a click to a mailto link.
// The mailto link is created dynamically using the data entered by the user in the form.

import { Modal } from "bootstrap";

const email_addresses = ["francesco@virdis-cossu.com", "giuliano@virdis-cossu.com"];
const form = document.querySelector("form#contactForm");
// Add an event listener to the link inside the form with id sendMailButton
form.querySelector("a#sendMailButton").addEventListener("click", sendMail);
// Create a modal to show when the form is not complete via bootstrap
const myModalAlternative = new Modal('#uncompleteFormModal', {})

function sendMail() {
    // Collect the data entered by the user in the form
    let name = form.querySelector("input[name='inputName']").value;
    let email = form.querySelector("input[name='inputEmail']").value;
    let subject = form.querySelector("input[name='inputSubject']").value;
    let message = form.querySelector("textarea[name='Message']").value;
    let phone = form.querySelector("input[name='inputPhone']").value;

    // Check if the form is complete, name, email and message are required
    if (name == "" || email == "" || message == "") {
        // If the form is not complete, show the uncomplete form modal 
        myModalAlternative.show();
        console.log("Form is not complete");
        return;
    }

    // Append the contact information to the message as a signature, format the newlines for compatibility with mailto
    message += "%0D%0A%0D%0A" + name + "%0D%0A" + email + "%0D%0A" + phone;

    // Create a mailto link with the data entered by the user
    let mailto_link = "mailto:" + email_addresses.join(",") + "?subject=" + subject + "&body=" + message;

    // Simulate a click to the mailto link
    window.location.href = mailto_link;
}
